import React, { Component } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import image1 from './photo-1.jpg'
import image2 from './photo-2.JPG'
import image3 from './photo-3.jpg'
import image4 from './photo-4.jpg'
import image5 from './photo-5.jpeg'
import image6 from './photo-6.JPG'


import './images.css';

class Images extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
        };

        return (
            <section id="images">
                <Slider {...settings}>
                        <img src={image1} alt="" />
                        <img src={image2} alt="" />
                        <img src={image3} alt="" />
                        <img src={image4} alt="" />
                        <img src={image5} alt="" />
                        <img src={image6} alt="" />
                </Slider>
            </section>
        );
    }
}

export default Images;